@import '../../assets/scss/variables';

.Dropdown-root {
  position: relative;
  font-size: 12px;
  background-color: $white;
  border: $bc;

  .Dropdown-control .Dropdown-arrow-wrapper {
    position: absolute;
    top: 8px; right: 8px;
    width: 16px;
    height: 16px;
    background: $white url('../../../assets/images/icon-arrow.svg') center center no-repeat;
    background-size: contain;
    transform: rotate(90deg)
  }

  &.is-open .Dropdown-control {
    border-bottom: $bc;

    .Dropdown-arrow-wrapper { transform: rotate(-90deg) }
  }

  .Dropdown-control,
  .Dropdown-option {
    padding: 7px 12px;
    cursor: pointer;
  }

  .Dropdown-menu {
    position: absolute;
    right: -1px; left: -1px;
    margin-top: -1px;
    height: 200px;
    background-color: $white;
    border: $bc;
    overflow: auto;
  }

  .Dropdown-option {
    &:hover { background-color: $light-grey; }

    &.is-selected {
      font-weight: 500;
      background-color: $grey;
    }
  }
}