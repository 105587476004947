@import '../../assets/scss/variables';

.cjd-accordin {
  max-width: 100%;

  border-radius: 25px;

  +.cjd-accordin {
    margin-top: 10px;
  }
}

.cjd-accordin-open {
  border: 2px solid $secondry-stroke;
  background-color: $secondry-inside;

  .cjd-accordin-wrapper {
    background-color: $secondry-inside;
  }

  .cjd-round-span {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background-color: $secondry-stroke;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.cjd-accordin-close {
  border: 2px solid $primary-stroke;
  background-color: $primary-inside;

  .cjd-accordin-wrapper {
    display: none;
  }

  .cjd-round-span {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background-color: $primary-stroke;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.cjd-label-wrapper {
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cjd-icon-wrapper {
    margin-top: -2px;
    float: right;

    &.cjd-expanded {
      transform: rotate(-90deg);
    }

    &.cjd-collapsed {
      transform: rotate(90deg);
    }
  }
}

.cjd-accordin-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  padding-top: 0;
  background: $bg;

  .more-info {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: bold;
    background-color: lightgray;
    border-radius: 100%;
    cursor: pointer;

    &::before,
    &::after {
      display: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }

    &:before {
      padding: 5px 10px;
      bottom: 30px;
      width: 200px;
      color: #fff;
      background-color: #000;
      content: 'Zip Out lining making it easy to add your own embroidery and patches without unsightly threads showing.';
    }

    &::after {
      top: -6px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      background: transparent;
      content: '';
    }

    &:hover::before,
    &:hover::after {
      display: block;
    }
  }
}


.select-label {
  color: #8585b3;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 3px;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100% !important;
}

.control-box.active {
  color: #fff;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #d6d6f0;
  border-radius: 5px;
  height: 47px;
  outline: none;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 45px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #4f4fa4;
  line-height: 44px;
  padding: 0 14px;
  text-transform: capitalize;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 39px;
  position: absolute;
  top: 5px;
  right: 7px;
  width: 20px;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-results {
  display: block;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-results__options {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #8585b3;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-results__option {
  background-color: #f4f4fd;
  border-bottom: 1px solid #d6d6f0;
  color: #8585b3;
  padding: 10px 15px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.control-panel-btn {
  border: 1px solid #d6d6f0;
  border-radius: 5px;
  color: #8585b3;
  display: block;
  float: left;
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  text-transform: capitalize;
  width: calc(50% - 11px);
  cursor: pointer;
}

.active {
  background-color: #8787c9;
  border-color: #8787c9;
  color: #fff;
}

.control-panel-btn:first-child {
  margin-right: 11px;
}

.control-panel-btn:active {
  background-color: #8787c9;
  border-color: #8787c9;
  color: #fff;
}

//Color Section

.control-box-color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //border: 1px solid #d6d6f0;
  padding-right: 10px;
  //border-bottom: 0;
  line-height: 58px;
  cursor: pointer;
}

.control-panel-color {
  border: 1px solid #d6d6f0;
  margin-top: 10px
}