$white: white;
$black: black;
$red: red;
$grey: #e6e6e6;
$light-grey: #fafafa;
$green: #74c143;
$blue: #0069ed;

$primary-stroke: #43bdd5;
$primary-inside: #eef6f8;
$secondry-stroke: #17a903;
$secondry-inside: #dafad9;
$gray-inside: #7b818d;
$gray-stroke: #3e4450;

$bg: $white;
$header: $bg;
$header-height: 60px;
$bc: 1px solid $grey;


$breakpoints: (
  'mobile':  ( max-width:  680px ),
  'small':  ( max-width:  933px ),
  'medium': ( max-width:  1200px ),
  'large':  ( max-width: 1200px )
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}